import React from 'react'

import { navigate, useParams } from '@reach/router'
import { useFormContext } from 'react-hook-form'
import { Button } from '@refera/ui-web'

import { createReferaUser, deleteReferaUser, updateReferaUser } from '_/modules/user/actions'

import HeaderTitle from '_/components/header-title'

import useStyles from './styles'

import { transformSubmitData } from './utils'

import { ROUTES } from '_/utils/constants'
import { useDispatch } from 'react-redux'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

export function PageHeader({ isLoading, setIsLoading, permissionsGroup }) {
  const { userId } = useParams()
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()
  const styles = useStyles()
  const { handleSubmit, ...otherFormMethods } = useFormContext()
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const onSaveButtonClick = async data => {
    if (
      checkIfUserDoesNotPermission(
        PERMISSIONS.REFERA_USERS,
        userId ? PERMISSIONS_ACTIONS.EDIT : PERMISSIONS_ACTIONS.ADD
      )
    ) {
      return
    }

    const isValid = await otherFormMethods.trigger()

    setIsLoading(true)

    // necessary for the first validation when the form hasn't been touched
    if (!isValid) {
      setIsLoading(false)
      return
    }

    const modifiedData = {
      ...transformSubmitData(data),
      permissionsGroup: permissionsGroup.map(permission => permission.id),
    }

    if (userId) {
      dispatch(updateReferaUser(userId, modifiedData))
        .then(() => {
          setIsLoading(false)
          showToast({
            type: 'success',
          })
        })
        .catch(e => {
          setIsLoading(false)
          showToast({
            message: e?.error_message ?? 'Erro ao atualizar usuário',
            type: 'error',
          })
        })
    } else {
      dispatch(createReferaUser(modifiedData))
        .then(response => {
          setIsLoading(false)
          showToast({
            type: 'success',
          })
          otherFormMethods.reset()
          navigate(`${ROUTES.REFERA_USERS}/${response?.id}`)
        })
        .catch(e => {
          setIsLoading(false)
          showToast({
            message: e?.error_message ?? 'Erro ao criar usuário',
            type: 'error',
          })
        })
    }
  }

  const handleDelete = () => {
    closeDialog()
    setIsLoading(true)

    dispatch(deleteReferaUser(userId))
      .then(() => {
        setIsLoading(false)
        showToast({
          type: 'success',
        })
        navigate(ROUTES.REFERA_USERS)
      })
      .catch(e => {
        setIsLoading(false)
        showToast({
          message: e?.error_message ?? 'Erro ao deletar usuário',
          type: 'error',
        })
      })
  }

  const deleteButtonClick = async () => {
    if (checkIfUserDoesNotPermission(PERMISSIONS.REFERA_USERS, PERMISSIONS_ACTIONS.DELETE)) {
      return
    }

    if (!userId) return

    showDialog({
      type: 'warning',
      labelApprove: 'Sim',
      labelCancel: 'Não',
      subject: 'Você tem certeza que deseja excluir esse registro?',
      onApprove: handleDelete,
      onCancel: closeDialog,
    })
  }

  return (
    <HeaderTitle title="Usuários da Refera" backButtonAction={() => navigate(ROUTES.REFERA_USERS)}>
      <div className={styles.actionButtons}>
        <Button
          style={{ width: '100px' }}
          onClick={() => navigate(ROUTES.REFERA_USERS)}
          disabled={isLoading}
          color="red"
          variant="secondary"
        >
          Cancelar
        </Button>
        <Button
          style={{ width: '100px' }}
          onClick={handleSubmit(onSaveButtonClick)}
          disabled={isLoading}
        >
          Salvar
        </Button>

        {userId && (
          <Button
            style={{ width: '100px' }}
            color="red"
            variant="primary"
            onClick={deleteButtonClick}
            disabled={isLoading}
          >
            Deletar
          </Button>
        )}
      </div>
    </HeaderTitle>
  )
}
